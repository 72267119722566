.pyetje-te-shpeshta-faq8 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.pyetje-te-shpeshta-max-width {
  gap: var(--dl-space-space-threeunits);
  align-self: center;
  align-items: flex-start;
}
.pyetje-te-shpeshta-section-title {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.pyetje-te-shpeshta-content {
  align-self: stretch;
  align-items: flex-start;
}
.pyetje-te-shpeshta-button {
  border-color: var(--dl-color-scheme-green80);
  background-color: var(--dl-color-scheme-green80);
}
.pyetje-te-shpeshta-list {
  flex: 1;
  width: 100%;
}
.pyetje-te-shpeshta-faq1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 0px;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.pyetje-te-shpeshta-trigger1 {
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: stretch;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-color: var(--dl-color-scheme-white);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.pyetje-te-shpeshta-icons-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.pyetje-te-shpeshta-icon10 {
  width: 24px;
  height: 24px;
}
.pyetje-te-shpeshta-icon12 {
  width: 24px;
  height: 24px;
}
.pyetje-te-shpeshta-container12 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pyetje-te-shpeshta-text12 {
  font-family: "Poppins";
}
.pyetje-te-shpeshta-faq2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 0px;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.pyetje-te-shpeshta-trigger2 {
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: stretch;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.pyetje-te-shpeshta-icons-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.pyetje-te-shpeshta-icon14 {
  width: 24px;
  height: 24px;
}
.pyetje-te-shpeshta-icon16 {
  width: 24px;
  height: 24px;
}
.pyetje-te-shpeshta-container15 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pyetje-te-shpeshta-text13 {
  font-family: "Poppins";
}
.pyetje-te-shpeshta-faq3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 0px;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.pyetje-te-shpeshta-trigger3 {
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: stretch;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.pyetje-te-shpeshta-icons-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.pyetje-te-shpeshta-icon18 {
  width: 24px;
  height: 24px;
}
.pyetje-te-shpeshta-icon20 {
  width: 24px;
  height: 24px;
}
.pyetje-te-shpeshta-container18 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pyetje-te-shpeshta-text14 {
  font-family: "Poppins";
}
.pyetje-te-shpeshta-faq4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 0px;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.pyetje-te-shpeshta-trigger4 {
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-self: stretch;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-color: rgba(0, 0, 0, 0);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.pyetje-te-shpeshta-icons-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.pyetje-te-shpeshta-icon22 {
  width: 24px;
  height: 24px;
}
.pyetje-te-shpeshta-icon24 {
  width: 24px;
  height: 24px;
}
.pyetje-te-shpeshta-container21 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pyetje-te-shpeshta-text15 {
  font-family: "Poppins";
}
.pyetje-te-shpeshta-text16 {
  display: inline-block;
  font-family: "Poppins";
}
.pyetje-te-shpeshta-text17 {
  display: inline-block;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
}
.pyetje-te-shpeshta-text18 {
  display: inline-block;
  font-family: "Poppins";
}
.pyetje-te-shpeshta-text19 {
  display: inline-block;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
}
.pyetje-te-shpeshta-text20 {
  display: inline-block;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
}
.pyetje-te-shpeshta-text21 {
  display: inline-block;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
}
.pyetje-te-shpeshta-text22 {
  display: inline-block;
}
.pyetje-te-shpeshta-text24 {
  font-family: "Poppins";
}
.pyetje-te-shpeshtaroot-class-name {
  margin-top: var(--dl-space-space-tripleunit);
}
@media(max-width: 991px) {
  .pyetje-te-shpeshta-max-width {
    flex-direction: column;
  }
  .pyetje-te-shpeshta-list {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pyetje-te-shpeshta-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .pyetje-te-shpeshta-trigger1 {
    width: auto;
    padding: var(--dl-space-space-doubleunit);
  }
  .pyetje-te-shpeshta-text20 {
    padding-top: var(--dl-space-space-doubleunit);
  }
}
