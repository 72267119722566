.footeri-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.footeri-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footeri-content {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: 4px;
  padding-right: var(--dl-space-space-halfunit);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.footeri-newsletter {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 291px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footeri-image1 {
  width: 170px;
  height: 114px;
  text-decoration: none;
}
.footeri-container2 {
  flex: 0 0 auto;
  width: auto;
  height: 44px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footeri-link10 {
  display: contents;
}
.footeri-logo2 {
  width: 130px;
  height: 44px;
  object-fit: contain;
  border-color: #fff9f9;
  border-width: 1px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-buttonradius);
  text-decoration: none;
}
.footeri-link11 {
  display: contents;
}
.footeri-logo1 {
  width: 130px;
  height: 44px;
  object-fit: contain;
  border-color: #fdfdfd;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-buttonradius);
  text-decoration: none;
}
.footeri-links {
  gap: var(--dl-space-space-twounits);
  width: 595px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.footeri-column1 {
  gap: var(--dl-space-space-unit);
  width: 178px;
  display: flex;
  overflow: hidden;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footeri-link5 {
  text-decoration: none;
}
.footeri-link3 {
  text-decoration: none;
}
.footeri-link4 {
  text-decoration: none;
}
.footeri-footer-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footeri-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.footeri-container4 {
  flex: 0 0 auto;
  width: auto;
  height: 160px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.footeri-content2 {
  color: rgba(0, 0, 0, 0);
  font-family: "Poppins";
}
.footeri-text10 {
  color: var(--dl-color-theme-secondary1);
  font-weight: 700;
  text-transform: capitalize;
}
.footeri-text11 {
  color: var(--dl-color-theme-secondary1);
}
.footeri-text12 {
  color: var(--dl-color-theme-secondary1);
}
.footeri-link12 {
  color: var(--dl-color-theme-secondary1);
  line-height: 2;
  text-decoration: underline;
}
.footeri-link13 {
  color: var(--dl-color-theme-secondary1);
  line-height: 2;
  text-decoration: underline;
}
.footeri-link15 {
  color: var(--dl-color-theme-accent1);
  line-height: 2;
}
.footeri-social-links {
  gap: var(--dl-space-space-unit);
  width: 140px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.footeri-link16 {
  display: contents;
}
.footeri-icon1 {
  fill: #D9D9D9;
  text-decoration: none;
}
.footeri-link17 {
  display: contents;
}
.footeri-icon3 {
  fill: #D9D9D9;
  text-decoration: none;
}
.footeri-link18 {
  display: contents;
}
.footeri-icon5 {
  fill: #D9D9D9;
  text-decoration: none;
}
.footeri-link19 {
  display: contents;
}
.footeri-icon7 {
  fill: var(--dl-color-theme-accent1);
  color: var(--dl-color-theme-accent1);
  height: auto;
  padding-top: var(--dl-space-space-unit);
  text-decoration: none;
}
.footeri-content1 {
  fill: var(--dl-color-theme-accent1);
  color: var(--dl-color-theme-accent1);
}
.footeri-credits {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footeri-row {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
.footeri-text19 {
  color: var(--dl-color-theme-secondary1);
  font-family: "Poppins";
}
.footeri-text20 {
  color: var(--dl-color-theme-secondary1);
  font-family: "Poppins";
}
.footeri-text21 {
  color: var(--dl-color-theme-secondary1);
  font-family: "Poppins";
}
.footeri-text22 {
  color: var(--dl-color-theme-secondary1);
  font-family: "Poppins";
}
.footeri-text23 {
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  font-family: "Poppins";
  font-weight: 300;
}
.footeri-text24 {
  color: var(--dl-color-theme-secondary1);
  font-family: "Poppins";
}
.footeri-text25 {
  color: var(--dl-color-theme-secondary1);
  font-family: "Poppins";
}
.footeri-text26 {
  color: var(--dl-color-theme-secondary1);
  font-family: "Poppins";
}
.footeriroot-class-name {
  width: 100%;
  height: 100%;
  background-color: #141B23;
}
@media(max-width: 1200px) {
  .footeri-content2 {
    font-family: Poppins;
  }
  .footeri-text10 {
    color: var(--dl-color-gray-900);
    font-weight: 700;
    text-transform: capitalize;
  }
  .footeri-text11 {
    color: var(--dl-color-theme-secondary1);
    text-transform: capitalize;
  }
  .footeri-text12 {
    text-transform: capitalize;
  }
  .footeri-link12 {
    color: var(--dl-color-gray-900);
    line-height: 2;
    text-decoration: underline;
  }
  .footeri-link13 {
    color: var(--dl-color-gray-900);
    line-height: 2;
    text-decoration: underline;
  }
  .footeri-text13 {
    color: var(--dl-color-gray-900);
    line-height: 2;
    text-decoration: underline;
  }
  .footeri-link15 {
    color: var(--dl-color-gray-900);
    line-height: 2;
  }
  .footeri-icon7 {
    height: auto;
    padding-top: var(--dl-space-space-unit);
  }
  .footeri-content1 {
    color: var(--dl-color-gray-900);
    font-family: Poppins;
  }
}
@media(max-width: 991px) {
  .footeri-newsletter {
    width: 300px;
  }
  .footeriroot-class-name {
    width: 100%;
    height: 100%;
    align-self: center;
  }
}
@media(max-width: 767px) {
  .footeri-content {
    flex-direction: column;
  }
  .footeri-newsletter {
    width: 100%;
  }
  .footeri-image1 {
    height: 97px;
  }
  .footeri-links {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  .footeri-column1 {
    align-items: center;
  }
  .footeri-footer-links {
    align-self: center;
  }
  .footeri-social-links {
    align-self: flex-start;
  }
  .footeri-row {
    gap: 0;
    flex-direction: column;
    justify-content: space-between;
  }
  .footeri-text19 {
    text-align: center;
  }
  .footeriroot-class-name {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .footeri-image1 {
    height: 113px;
  }
  .footeri-links {
    flex-direction: column;
  }
  .footeri-column1 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footeri-footer-links {
    align-items: center;
    justify-content: center;
  }
  .footeri-social-links {
    align-items: center;
    justify-content: center;
  }
  .footeri-credits {
    gap: 0;
  }
  .footeri-row {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    justify-content: center;
  }
  .footeri-text25 {
    color: var(--dl-color-theme-secondary1);
    font-family: Poppins;
  }
  .footeriroot-class-name {
    width: auto;
  }
}
