.home-container1 {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-container2 {
  width: 100%;
  height: 81px;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.home-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-image1 {
  width: 100px;
  height: 100px;
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-link11 {
  font-size: 18px;
  font-family: "Poppins";
  text-decoration: none;
}
.home-link21 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  text-decoration: none;
}
.home-link31 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  text-decoration: none;
}
.home-link41 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  text-decoration: none;
}
.home-buttons {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
}
.home-action1 {
  display: flex;
  z-index: 100;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-scheme-green80);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-scheme-green80);
}
.home-text10 {
  font-family: "Poppins";
}
.home-burger-menu {
  display: none;
}
.home-icon10 {
  width: 16px;
  height: 16px;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  transform: translateX(-100%);
  transition: 0.5s;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo {
  height: 3rem;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon12 {
  width: 16px;
  height: 16px;
}
.home-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  scroll-behavior: smooth;
}
.home-link12 {
  font-size: 18px;
  font-family: "Poppins";
  text-decoration: none;
}
.home-link22 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  text-decoration: none;
}
.home-link32 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  text-decoration: none;
}
.home-link42 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  text-decoration: none;
}
.home-link5 {
  display: contents;
}
.home-image2 {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.home-link6 {
  display: contents;
}
.home-image3 {
  width: 200px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
  text-decoration: none;
}
.home-hero {
  width: 100%;
  height: 911px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  background-size: cover;
  justify-content: center;
  background-image: url("/add%20a%20heading%20(1)-1000h.png");
  border-bottom-left-radius: var(--dl-radius-radius-radius24);
  border-bottom-right-radius: var(--dl-radius-radius-radius24);
}
.home-content-container1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tenunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: center;
}
.home-hero-text {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-heading1 {
  width: 645px;
  height: 342px;
  font-size: 48px;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 800;
}
.home-text11 {
  color: var(--dl-color-theme-neutral-dark);
}
.home-text12 {
  color: var(--dl-color-theme-neutral-dark);
}
.home-text13 {
  color: var(--dl-color-theme-neutral-dark);
}
.home-text14 {
  color: var(--dl-color-theme-neutral-dark);
}
.home-text15 {
  color: var(--dl-color-theme-neutral-dark);
}
.home-text16 {
  color: #f2f8f3;
}
.home-text17 {
  color: var(--dl-color-scheme-green80);
}
.home-cta-btn {
  left: 0px;
  color: var(--dl-color-gray-white);
  bottom: 0px;
  position: absolute;
  font-size: 18px;
  box-shadow: 5px 5px 10px 0px #151414;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.55;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
  text-decoration: none;
  background-color: var(--dl-color-scheme-green80);
}
.home-our-story {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container1 {
  width: 45%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text18 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text19 {
  font-size: 18px;
  text-align: center;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-cards-container1 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-left-section {
  width: 50%;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  margin-right: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.home-video-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-gray-900);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-video {
  width: 100%;
  height: auto;
  position: relative;
  border-radius: var(--dl-radius-radius-radius24);
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: var(--dl-radius-radius-radius2);
  border-bottom-right-radius: var(--dl-radius-radius-radius2);
}
.home-content-container2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: flex-start;
}
.home-heading2 {
  font-size: 24px;
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-text23 {
  font-size: 18px;
  font-style: normal;
  font-family: "Raleway";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-text24 {
  font-size: 18px;
}
.home-right-section {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
}
.home-card1 {
  width: 48%;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: space-between;
}
.home-image4 {
  width: 100%;
  height: 260px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-content-container3 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-card2 {
  width: 48%;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: space-between;
}
.home-image5 {
  width: 100%;
  height: 260px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-content-container4 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-radius24);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #F9F7F6;
}
.home-heading-container2 {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text31 {
  font-size: 48px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1.22;
  text-transform: none;
  text-decoration: none;
}
.home-cards-container2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-features-card1 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container1 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: rgba(16, 87, 73, 0.58);
}
.home-icon14 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading3 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-text32 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 50px;
}
.home-features-card2 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: rgba(16, 87, 73, 0.58);
}
.home-icon17 {
  width: 36px;
  height: 36px;
}
.home-text-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading4 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-text33 {
  font-size: 50px;
  font-style: normal;
  font-family: "Raleway";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-features-card3 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container3 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: rgba(16, 87, 73, 0.58);
}
.home-icon19 {
  width: 36px;
  height: 36px;
}
.home-text-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading5 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-text34 {
  font-size: 50px;
  font-style: normal;
  font-family: "Raleway";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-features-card4 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container4 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: rgba(16, 87, 73, 0.58);
}
.home-text-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading6 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-text35 {
  font-size: 50px;
  font-style: normal;
  font-family: "Raleway";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-features-card5 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container5 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: rgba(16, 87, 73, 0.58);
}
.home-text-container5 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading7 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-text36 {
  font-size: 50px;
  font-style: normal;
  font-family: "Raleway";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-features-card6 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container6 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: rgba(16, 87, 73, 0.58);
}
.home-icon29 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container6 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading8 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-text37 {
  font-size: 50px;
  font-style: normal;
  font-family: "Raleway";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-services {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-buttonradius);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
  background-color: #F9F7F6;
}
.home-heading-container3 {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text38 {
  font-size: 48px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
}
.home-cards-container3 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-service-card1 {
  width: 30%;
  height: 247px;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-buttonradius);
  flex-direction: column;
  justify-content: center;
  background-color: #FFFFFF;
}
.home-text39 {
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-fourunits);
  text-transform: none;
  text-decoration: none;
}
.home-text40 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  text-align: left;
  font-family: "Poppins";
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-service-card2 {
  width: 30%;
  height: 247px;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-buttonradius);
  flex-direction: column;
  justify-content: center;
  background-color: #FFFFFF;
}
.home-text43 {
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-fourunits);
  text-transform: none;
  text-decoration: none;
}
.home-text44 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  text-align: left;
  font-family: "Poppins";
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-service-card3 {
  width: 30%;
  height: 247px;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-buttonradius);
  flex-direction: column;
  justify-content: center;
  background-color: #FFFFFF;
}
.home-text48 {
  font-family: "Poppins";
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text49 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  text-align: left;
  font-family: "Poppins";
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-team {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #F9F7F6;
}
.home-heading-container4 {
  width: 45%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-heading9 {
  font-size: 48px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1.22;
  text-transform: none;
  text-decoration: none;
}
.home-cards-container4 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-team-card1 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
  padding-bottom: 32px;
}
.home-avatar-container1 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: rgba(16, 87, 73, 0.58);
}
.home-icon34 {
  width: 48px;
  height: 48px;
}
.home-position1 {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-team-card2 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-avatar-container2 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: 32px;
  background-color: rgba(16, 87, 73, 0.58);
}
.home-icon36 {
  width: 48px;
  height: 48px;
}
.home-position2 {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-team-card3 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-avatar-container3 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: 32px;
  background-color: rgba(16, 87, 73, 0.58);
}
.home-icon38 {
  width: 48px;
  height: 48px;
}
.home-position3 {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-team-card4 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-avatar-container4 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: 32px;
  background-color: rgba(16, 87, 73, 0.58);
}
.home-icon40 {
  width: 48px;
  height: 48px;
}
.home-position4 {
  color: var(--dl-color-scheme-green100);
  font-family: "Poppins";
  font-weight: 500;
}
.home-team-card5 {
  display: none;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-avatar-container5 {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon42 {
  width: 48px;
  height: 48px;
}
.home-text53 {
  display: inline-block;
  font-family: "Poppins";
}
.home-text54 {
  display: inline-block;
}
.home-text56 {
  font-family: "Poppins";
}
.home-text57 {
  display: inline-block;
  font-family: "Poppins";
}
.home-text58 {
  display: inline-block;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
}
.home-text59 {
  display: inline-block;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
}
.home-text60 {
  display: inline-block;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
}
.home-text61 {
  display: inline-block;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
}
.home-banner {
  flex: 0 0 auto;
  width: 1300px;
  display: flex;
  padding: var(--dl-space-space-tenunits);
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  border-radius: 48px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/moduli-5-desktop-icharge-financim-1200w-1500w.png");
}
.home-text62 {
  color: var(--dl-color-scheme-white);
  font-size: 40px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text67 {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-family: "Poppins";
  margin-bottom: var(--dl-space-space-twounits);
}
.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-section-separator3 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-component2 {
  text-decoration: none;
}
.home-text74 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-family: "Poppins";
}
.home-text75 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-family: "Poppins";
}
.home-text76 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-family: "Poppins";
}
.home-text77 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-family: "Poppins";
}
.home-text78 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-family: "Poppins";
}
.home-text79 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-family: "Poppins";
}
.home-text80 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-family: "Poppins";
}
.home-text81 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  font-family: "Poppins";
}
@media(max-width: 1600px) {
  .home-hero {
    height: 758px;
  }
  .home-heading1 {
    font-family: Poppins;
  }
  .home-text11 {
    color: inherit;
  }
  .home-text12 {
    color: inherit;
  }
  .home-text13 {
    color: inherit;
  }
  .home-text14 {
    color: inherit;
  }
  .home-text15 {
    color: inherit;
  }
  .home-text16 {
    color: inherit;
  }
  .home-text17 {
    color: var(--dl-color-scheme-green80);
  }
  .home-cta-btn {
    box-shadow: 5px 5px 10px 0px rgba(21, 20, 20, 0.52);
  }
  .home-video {
    height: auto;
  }
  .home-banner {
    width: 100%;
    height: auto;
  }
}
@media(max-width: 1200px) {
  .home-link31 {
    font-size: 18px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    text-decoration: none;
  }
  .home-hero {
    background-position: top;
  }
  .home-video {
    height: 100%;
    border-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .home-right-section {
    height: auto;
  }
  .home-image4 {
    width: 100%;
    height: 100%;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-top-right-radius: var(--dl-radius-radius-radius24);
  }
  .home-content-container3 {
    height: auto;
  }
  .home-image5 {
    width: 100%;
    height: auto;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-top-right-radius: var(--dl-radius-radius-radius24);
  }
  .home-content-container4 {
    height: auto;
  }
  .home-text32 {
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
  }
  .home-text33 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
  }
  .home-text34 {
    fill: var(--dl-color-theme-neutral-dark);
    color: var(--dl-color-theme-neutral-dark);
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
  }
  .home-text35 {
    fill: var(--dl-color-theme-neutral-dark);
    color: var(--dl-color-theme-neutral-dark);
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
  }
  .home-text36 {
    fill: var(--dl-color-theme-neutral-dark);
    color: var(--dl-color-theme-neutral-dark);
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
  }
  .home-text37 {
    fill: var(--dl-color-theme-neutral-dark);
    color: var(--dl-color-theme-neutral-dark);
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
  }
  .home-text38 {
    font-family: Poppins;
  }
  .home-text40 {
    color: var(--dl-color-gray-700);
  }
  .home-text44 {
    color: var(--dl-color-gray-700);
  }
  .home-text49 {
    color: var(--dl-color-gray-700);
  }
  .home-banner {
    width: 100%;
  }
}
@media(max-width: 991px) {
  .home-hero {
    flex-direction: column-reverse;
  }
  .home-content-container1 {
    width: auto;
    max-width: auto;
    flex-direction: column-reverse;
  }
  .home-hero-text {
    width: 80%;
  }
  .home-cards-container1 {
    flex-direction: column;
  }
  .home-left-section {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-right-section {
    width: 100%;
  }
  .home-cards-container4 {
    justify-content: center;
  }
  .home-banner {
    width: auto;
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .home-content-container1 {
    width: 766px;
  }
  .home-heading1 {
    width: auto;
  }
  .home-heading-container1 {
    width: 100%;
  }
  .home-cards-container1 {
    align-items: center;
    flex-direction: column;
  }
  .home-heading-container2 {
    width: 100%;
  }
  .home-cards-container2 {
    align-items: center;
    flex-direction: column;
  }
  .home-features-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card2 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card3 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card4 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card5 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card6 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-heading-container3 {
    width: 100%;
  }
  .home-cards-container3 {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  .home-service-card1 {
    width: 65%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-service-card2 {
    width: 65%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-service-card3 {
    width: 65%;
  }
  .home-heading-container4 {
    width: 100%;
  }
  .home-banner {
    width: auto;
    padding: var(--dl-space-space-twounits);
  }
  .home-text81 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .home-navbar-interactive {
    width: 100%;
    padding: var(--dl-space-space-unit);
    padding-right: 8px;
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
  }
  .home-icon10 {
    width: 40px;
    height: 100%;
    z-index: 100;
  }
  .home-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
  .home-nav {
    height: 988px;
    position: relative;
  }
  .home-top {
    height: 125px;
    margin-bottom: 0px;
  }
  .home-logo {
    width: 180px;
    height: 154px;
  }
  .home-icon12 {
    width: 25px;
    height: 25px;
  }
  .home-links2 {
    width: 442px;
    height: 354px;
    align-self: center;
    /* border-color: rgba(25, 24, 24, 0.59); */
    /* border-width: 1px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px; */
  }
  .home-link12 {
    font-size: 26px;
    align-self: center;
    margin-top: var(--dl-space-space-fiveunits);
    font-family: Poppins;
    margin-bottom: var(--dl-space-space-twounits);
    text-decoration: none;
  }
  .home-link22 {
    font-size: 26px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
    text-decoration: none;
  }
  .home-link32 {
    font-size: 26px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
    text-decoration: none;
  }
  .home-link42 {
    font-size: 26px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
    text-decoration: none;
  }
  .home-image2 {
    left: 0px;
    width: 200px;
    bottom: var(--dl-space-space-tenunits);
    height: 55px;
    position: absolute;
    border-radius: var(--dl-radius-radius-radius24);
    margin-bottom: 50px;
  }
  .home-image3 {
    right: 0px;
    width: 200px;
    bottom: var(--dl-space-space-tenunits);
    height: 55px;
    position: absolute;
    align-self: flex-end;
    border-radius: var(--dl-radius-radius-radius24);
    margin-bottom: 50px;
  }
  .home-hero {
    width: 100%;
    height: 794px;
    align-self: flex-start;
    align-items: center;
    padding-top: var(--dl-space-space-fourunits);
    padding-right: 0px;
    flex-direction: column-reverse;
    padding-bottom: var(--dl-space-space-fourunits);
    background-size: cover;
    background-image: 95f13d6b-1351-42ff-9134-daddda00af69;
    background-position: top;
  }
  .home-content-container1 {
    width: 100%;
    height: 1004px;
    max-width: auto;
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: 0px;
  }
  .home-hero-text {
    width: 372px;
    height: 538px;
    position: relative;
    align-self: center;
    margin-bottom: var(--dl-space-space-tenunits);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-heading1 {
    width: auto;
    height: auto;
    font-size: 40px;
    align-self: center;
    margin-top: 0px;
    text-align: center;
    font-family: Poppins;
    padding-top: var(--dl-space-space-halfunit);
    margin-right: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text17 {
    color: var(--dl-color-scheme-green80);
  }
  .home-cta-btn {
    left: 86px;
    width: 200px;
    bottom: var(--dl-space-space-tenunits);
    position: absolute;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-our-story {
    padding-top: var(--dl-space-space-fiveunits);
  }
  .home-text18 {
    text-align: center;
  }
  .home-video {
    width: 100%;
    height: 100%;
    border-radius: var(--dl-radius-radius-radius8);
    border-bottom-left-radius: var(--dl-radius-radius-radius2);
    border-bottom-right-radius: var(--dl-radius-radius-radius2);
  }
  .home-heading2 {
    font-size: 24px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 700;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }
  .home-card1 {
    width: 100%;
  }
  .home-card2 {
    width: 100%;
  }
  .home-image5 {
    width: 100%;
    height: auto;
  }
  .home-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-features-card1 {
    width: 100%;
  }
  .home-features-card2 {
    width: 100%;
  }
  .home-features-card3 {
    width: 100%;
  }
  .home-features-card4 {
    width: 100%;
  }
  .home-features-card5 {
    width: 100%;
  }
  .home-features-card6 {
    width: 100%;
  }
  .home-services {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-service-card1 {
    width: 100%;
  }
  .home-service-card2 {
    width: 100%;
  }
  .home-service-card3 {
    width: 100%;
  }
  .home-team {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text59 {
    padding-top: var(--dl-space-space-doubleunit);
  }
  .home-banner {
    width: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text75 {
    color: var(--dl-color-theme-secondary1);
    font-family: Poppins;
  }
}
